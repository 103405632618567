/* svg specific styling can be done here */
/* Material UI specific icon style overriding can be done */

.material-icons.md-12 {
  font-size: 12px;
}
.material-icons.md-14 {
  font-size: 14px;
}
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}

