$all-mui-xs: "all and (max-width : 599px)";

.questionnaire-page-container {
  background: white;
  margin: 1.5rem;
  // border: 1px solid $color-greyBlue;
  padding-bottom: 200px;

  @media #{$all-mui-xs} {
    border: 0;
    background: transparent;
    margin:0;
  }

  header {
    padding: 45px 0;
    margin: 0 50px 30px 50px;
    font-size: 28px;
    font-weight:bold;
    position: relative;

    .header-center-align{
        display:flex;
        justify-content: center;
    }
    .header-left-align{
        display:flex;
        justify-content: left;
    }
  }

  .content-parser-container {
    margin-right: 50px;
    margin-left: 50px;

    @media #{$all-mui-xs} {
      margin-right: 0;
      margin-left: 0;
      padding: 0 1.5rem 1.5rem;
      background: white;
      border: 1px solid $color-greyBlue;

        &.mobile-border-none{
            border:none;
        }
    }
  }
}

.content-parser {
  &-root {
    width: 100%;
  }

  &-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 16px;

    .entity-table {
      width: 100%;
    }

    .horizontal-table {
      width: 100%;
    }

    .full-width {
      width: 100%;
    }

    .description-wrapper {
      display: inline-block;

      .description-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .control-label {
      display: block;
      //   width: 97%;
      margin-bottom: 8px;
      font-size: 1rem;
      line-height: 1;
    }
    &.col-num-1 {
      width: 100%;
      .control-label {
        margin-bottom: 8px;
      }

      .content-fullwidth {
        display: block;
      }
    }

    &.col-num-2 {
      width: 100%;
      .control-label {
        margin-right: 16px;
      }
    }

    &.col-num-3 {
      width: 100%;
      .control-label {
        margin-right: 16px;
      }
    }
    &.content-parser-center {
      //   padding-left: 35%;

      @media screen and (max-width: 640px) {
        padding-left: 0%;
      }
      .control-label {
        // text-align: center;
      }
    }
    &.content-parser-Right {
      margin-left: 65%;
      @media screen and (max-width: 640px) {
        margin-left: 0%;
      }
      .control-label {
        text-align: right;
      }
    }
    &.content-parser-Left {
      margin-left: 0%;
      .control-label {
        text-align: left;
      }
    }

    @media screen and (max-width: 640px) {
      display: block;
    }
  }

  &-question {
    flex-basis: 40%;
    display: flex;
    align-items: center;
  }

  &-answer {
    flex-basis: 60%;
  }
}


/* controls css if not inside component */

