* { 
    font-family: Roboto, Verdana, sans-serif;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .bgPrimaryDarkBlueFB {
    background-color: #143247;
  }
  .bgSecondaryFBgrey {
    background-color: #eee;
  }
  .FBfamilyImage {
    background-image: url("../../../assets/fbFamilyWidescreenXxl.png");
    height: 428px;
    background-repeat: no-repeat;
  }
  @media (max-width: 1199.98px) {
    .FBfamilyImage {
      background-image: url("../../../assets/fbFamilyWidescreenXl.png");
      height: 348px;
      background-repeat: no-repeat;
    }
  }
  @media (max-width: 991.98px) {
    .FBfamilyImage {
      background-image: url("../../../assets/fbFamilyWidescreenLg.png");
      height: 294px;
      background-repeat: no-repeat;
    }
  }
  @media (max-width: 767.98px) {
    .FBfamilyImage {
      background-image: url("../../../assets/fbFamilyMd.png");
      height: 423px;
      background-repeat: no-repeat;
    }
  }
  @media (max-width: 475.98px) {
    .FBfamilyImage {
      background-image: url("../../../assets/fbFamilySm.png");
      height: 317px;
      background-repeat: no-repeat;
    }
  }
  .responsive {
    width: 100%;
    height: auto;
  }
  
  .outerDiv
      { height : 270px;
        margin : 5px; border: solid 0px;
      }
  
  .innerDiv
  { height     : 98%;
    overflow-y : auto;  
  }

  .agentCard {
    width: 250px;
    height: 320px;
    padding-top: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: auto;
    text-align: center;
    z-index: 5;
  }

  .agentCard img {
      max-width: 100%;
      margin-right: 20px;
  }

  .agentCard h2 {
      color: #333;
      margin: 5px;
      padding: 0;
      font-size: 15pt;
  }

  .agentCard p {
      color: #555;
      font-size: 13px;
  }
  .agentCardItalic p {
    font-style: italic;
  }
  .mask {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
  }