@import "./mixins.scss";

.pad-top-1 {
  padding-top: 1rem;
}
.mar-btm-1 {
  margin-bottom: 0.5rem;
}

.d-block {
  display: block;
}

.fr {
  float: right;
}
.fl {
  float: left;
}

.padBtm80 {
  padding-bottom: 80px;
}
