.spinner{
  margin-top: 20%;
  margin-left: calc(50% - 30px);
  margin-bottom: 80%;
  .service-spinner {
    position: relative;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px)
  }
}